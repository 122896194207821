@import './variables.scss';
@import './typography.scss';

:root {
  margin: 0;
  padding: 0;
}

html {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell","Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: var(--lightest-gray-color);
  border-radius: 3px;
  padding: .2em .4em;
}

a {
  text-decoration: none;
  color: var(--blue-color);
  font-weight: 700;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

hr {
  width: 100%;
  max-width: 320px;
  height: 1px;
  border: none;
  background: var(--lighter-gray-color);
}

div {
  box-sizing: border-box;
}

blockquote {
  border-left: .25em solid #dfe2e5;
  color: #6a737d;
  padding: 0 1em;
}

@media (pointer: coarse) {
  html {
    position: initial;
    overflow: initial;
  }
}
