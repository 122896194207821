.screenshot {
  $width: 300px;
  $padding: $width / 397px * 24px;

  display: inline-block;
  padding: $padding;
  margin: 0 20px 1.7rem;
  box-sizing: border-box;
  background-image: url(./frame-xs-max.png);
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
  width: $width;
  font-size: 0;

  &.top {
    padding-bottom: 0;
    border-bottom: 1px solid #e6e6e6;
  }

  &.bottom {
    padding-top: 0;
    border-top: 1px solid #e6e6e6;
    background-position: bottom;
  }

  img {
    position: relative;
    width: 100%;
    z-index: -1;
  }
}
