.container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

@media (pointer: coarse) {
  .container {
    position: initial;
  }
}
