:root {
  --white-color: #ffffff;
  --lightest-gray-color: #f3f3f5;
  --lighter-gray-color: #d9d9dc;
  --light-gray-color: #c0c0c0;
  --gray-color: #808080;
  --dark-gray-color: #505050;
  --darker-gray-color: #272a2e;
  --darkest-gray-color: #202020;
  --purple-color: #9d62f3;
  --pink-color: #fc00ff;
  --yellow-color: #fbc630;
  --aqua-color: #00dbde;
  --blue-color: #7688ed;
  --shadow-color: rgba(0, 0, 0, .3);
}
