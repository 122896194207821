@charset "UTF-8";
.container {
  --navbar-height: 60px;
  --min-aside-width: 237px;
  --max-content-width: 760px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1.6; }
  .container pre,
  .container code {
    font-size: 14px; }
  .container::after, .container::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }
  .container::after {
    height: var(--navbar-height);
    background: var(--pink-color);
    background: linear-gradient(to right, var(--pink-color), var(--aqua-color));
    z-index: -2; }
  .container::before {
    height: calc(var(--navbar-height) - 6px);
    background: var(--shadow-color);
    z-index: -1; }

.aside {
  min-width: var(--min-aside-width);
  height: 100%;
  flex-grow: 1; }

.content {
  flex-grow: 1;
  overflow-y: scroll;
  margin-top: var(--navbar-height); }

.navbar {
  padding: 0 1.7rem 6px;
  height: var(--navbar-height);
  color: var(--white-color);
  text-align: right; }
  .navbar .logo,
  .navbar .soft {
    display: inline-block;
    font-size: 20px;
    line-height: calc(var(--navbar-height) - 6px);
    color: var(--white-color);
    vertical-align: middle;
    text-decoration: none; }
  .navbar .logo {
    font-weight: 700; }
  .navbar .soft {
    opacity: 0.8;
    font-weight: 600;
    margin-left: 8px; }
  .navbar .menuIcon {
    display: none;
    right: 0;
    padding: .7rem;
    margin-right: 1rem;
    height: calc(var(--navbar-height) - 6px);
    position: absolute;
    cursor: pointer; }
    .navbar .menuIcon span, .navbar .menuIcon span::before,
    .navbar .menuIcon span::after {
      display: block;
      width: 24px;
      height: 2px;
      border-radius: 2px;
      background: var(--white-color); }
    .navbar .menuIcon span {
      position: relative;
      top: calc(50% - 1px); }
    .navbar .menuIcon span::before,
    .navbar .menuIcon span::after {
      position: absolute;
      display: block;
      content: ''; }
    .navbar .menuIcon span::before {
      top: 7px; }
    .navbar .menuIcon span::after {
      bottom: 7px; }
    .navbar .menuIcon.close span {
      background: none; }
    .navbar .menuIcon.close span::before,
    .navbar .menuIcon.close span::after {
      top: 0;
      bottom: 0; }
    .navbar .menuIcon.close span::before {
      transform: rotate(45deg); }
    .navbar .menuIcon.close span::after {
      transform: rotate(-45deg); }

.menu {
  text-align: right;
  overflow-y: scroll;
  padding: 1.4rem 1.7rem 40px;
  height: calc(100% - var(--navbar-height)); }
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .menu ul li {
      margin: 0; }
  .menu hr {
    margin: 1rem 0 1rem auto;
    max-width: calc(var(--min-aside-width) - 2 * 1.7rem); }
  .menu .menuItem, .menu .submenuItem {
    display: block;
    line-height: 2;
    text-decoration: none; }
    .menu .menuItem:hover, .menu .submenuItem:hover, .menu .active.menuItem, .menu .active.submenuItem {
      color: var(--blue-color); }
  .menu .menuItem {
    font-size: 18px;
    color: var(--darker-gray-color);
    transition: color 0.2s ease; }
  .menu .submenuItem {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-color); }
    .menu .submenuItem::after {
      content: ' ⚬'; }

@media (max-width: 700px) {
  .container {
    flex-direction: column; }
  .aside {
    width: 100%;
    height: auto;
    flex-grow: 0; }
  .content {
    margin-top: 0;
    flex: 1; }
  .navbar {
    text-align: left; }
    .navbar .menuIcon {
      display: inline-block; }
  .menu {
    display: none;
    text-align: left; }
    .menu .menuItem, .menu .submenuItem {
      line-height: 44px; }
    .menu li ul,
    .menu li + li {
      border-top: 1px solid var(--lightest-gray-color); }
    .menu hr {
      max-width: none; }
    .menu .menuItem {
      font-size: 18px; }
    .menu .submenuItem {
      font-size: 15px; }
      .menu .submenuItem::after {
        content: none; }
  .menuShown .aside {
    height: 100%; }
  .menuShown .content {
    display: none; }
  .menuShown .menu {
    display: block; } }

@media (pointer: coarse) {
  .container {
    height: auto;
    min-height: 100%; }
  .menu,
  .content {
    overflow: hidden; }
  .menuShown .aside {
    flex: 1; } }
